<template>
  <v-dialog
    v-model="redactModal"
    width="80%"
    @click:outside="$emit('update:redactModal', false)"
  >
    <v-card v-if="redactingBanner" class="modal" :dark="$dark.get()">
      <v-card-title>
        Редактирование баннера {{ redactingBanner.bannerName }}
      </v-card-title>
      <v-card-text class="modal__text">
        <loader v-if="loading"></loader>
        <!-- Настройки баннера -->
        <v-select
          v-if="!isCategoryBanners"
          :value="redactingBanner.bannerType ? redactingBanner.bannerType.id : 0"
          class="modal__select"
          label="Тип баннера"
          :items="types"
          :rules="[$validate.required]"
          required
          item-text="name"
          item-value="id"
          @change="addType($event)"
        ></v-select>
        <div v-if="redactingBanner.bannerType">
          <!-- для баннеров с категорией -->
          <v-autocomplete
            v-if="redactingBanner.bannerType.id !== 3"
            v-show="!mainCategory"
            ref="category"
            :value="redactingBanner.categoryId"
            :items="categoryes"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Категория баннера"
            clearable
            @change="searchCategory = $event"
            @click:clear="searchCategory = null"
          />
          <!-- для баннеров с продуктами -->
          <v-text-field
            v-else
            :value="redactingBanner.productId"
            :rules="[$validate.required]"
            label="Id продукта"
            type="number"
            required
            clearable
            @change="productId = $event"
          ></v-text-field>
          <!-- присваивание филиала -->
          <v-autocomplete
            v-if="redactingBanner.bannerType.id !== 3 && !isCategoryBanners"
            v-show="!mainCategory"
            ref="department"
            :value="redactingBanner.storeOpeningDepartment"
            :items="DEPARTMENT.cityDepartment"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Филиал для перехода"
            clearable
            @change="departmentId = $event"
            @click:clear="departmentId = null"
          />
          <!-- для баннеров с категорией, категория для перехода -->
          <v-autocomplete
            v-if="isCategoryBanners"
            :value="redactingBanner.linkedCategoryId"
            :items="categoryes"
            item-text="name"
            item-value="id"
            no-data-text="Ничего не найдено"
            class="mt-3"
            label="Категория для перехода"
            clearable
            @change="clickedCategory = $event"
            @click:clear="clickedCategory = null"
          />
          <v-checkbox
            v-if="isCategoryBanners"
            v-model="mainCategory"
            label="Сделать баннер корневым"
            hide-details
          ></v-checkbox>
        </div>

        <!-- Раздел обновления изображений -->
        <v-divider class="my-3"></v-divider>
        <v-card-text>
          <h3>Обновить изображения</h3>
          <v-file-input
            v-model="updateDesktop"
            accept="image/*"
            label="Обновить Banner Desktop (сайт)"
          />
          <v-file-input
            v-model="updateMobile"
            accept="image/*"
            label="Обновить Banner Mobile (мобильный сайт)"
          />
          <v-file-input
            v-model="updateMobileWeb"
            accept="image/*"
            label="Обновить Banner Mobile Web (мобильная версия сайта)"
          />
          <v-btn color="primary" class="mt-2" @click="updateImages">
            Обновить изображения
          </v-btn>
        </v-card-text>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="$emit('update:redactModal', false)">Закрыть</v-btn>
        <div v-if="redactingBanner.bannerType" class="ma-2">
          <v-btn
            v-if="redactingBanner.bannerType.id !== 3"
            color="#5d4bd0"
            dark
            @click="addCategoryOrDepartment()"
          >
            Изменить
          </v-btn>
          <v-btn v-else color="#5d4bd0" dark @click="addProduct()">
            Добавить продукт
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import api from "@/api";

export default {
  name: "RedactBannerDialog",
  components: { loader },
  props: {
    redactModal: {
      type: Boolean,
      default: false,
    },
    redactingBanner: {
      type: Object,
      default() {
        return {};
      },
    },
    categoryes: {
      type: Array,
      default() {
        return [];
      },
    },
    isCategoryBanners: {
      type: Boolean,
      default: false,
    },
    chosenDepartment: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      searchCategory: null,
      clickedCategory: null,
      mainCategory: false,
      productId: null,
      departmentId: null, // для привязки департамента к баннеру (например, для микромагазинов)
      types: [], // список типов баннеров, загружается через API
      // Новые переменные для обновления изображений:
      updateDesktop: null,
      updateMobile: null,
      updateMobileWeb: null,
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
    }),
  },
  watch: {
    redactModal(val) {
      if (!val) {
        this.searchCategory = null;
        this.productId = null;
        this.mainCategory = false;
        // Очистим данные для обновления изображений при закрытии диалога
        this.updateDesktop = null;
        this.updateMobile = null;
        this.updateMobileWeb = null;
      }
      if (!this.redactingBanner) return;
      if (this.redactingBanner.categoryId === 0) {
        this.mainCategory = true;
      }
    },
    mainCategory(val) {
      if (val) {
        this.searchCategory = 0;
      }
    },
    departmentId(val) {
      if (val) {
        this.$refs.category && this.$refs.category.reset();
        this.searchCategory = null;
      }
    },
    searchCategory(val) {
      if (val) {
        this.$refs.department && this.$refs.department.reset();
        this.departmentId = null;
      }
    },
  },
  mounted() {
    // Загружаем список типов баннеров через новый API
    api.Banner.getBannerTypes()
      .then((data) => {
        // Предполагаем, что API возвращает массив объектов с полями id и name
        this.types = data;
      })
      .catch((error) => {
        console.error("Ошибка при получении типов баннеров:", error);
      });
  },
  methods: {
    async addCategoryOrDepartment() {
      this.loading = true;
      let payload = {
        bannerId: this.redactingBanner.bannerId,
        id: this.redactingBanner.bannerId,
        categoryId:
          this.searchCategory !== null
            ? this.searchCategory
            : this.redactingBanner.categoryId,
      };
      let response;
      if (this.isCategoryBanners) {
        payload.categoryBannerId = this.redactingBanner.categoryBannerId;
        payload.cityId = this.chosenDepartment * -1;
        payload.linkedCategoryId =
          this.clickedCategory || this.redactingBanner.linkedCategoryId;
        response = await api.Banner.bindBannerWithCategory(payload);
      } else {
        payload.storeOpeningDepartment = this.departmentId;
        if (this.departmentId) {
          payload.categoryId = null;
        }
        response = await api.Banner.addCategoryToBanner(payload);
      }
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("К баннеру добавлена категория", true);
        this.$emit("updateBanners");
        this.$emit("update:redactModal", false);
      }
      this.loading = false;
    },
    async addProduct() {
      if (!this.productId) {
        ShowMessage("Добавьте или измените ID продукта");
        return;
      }
      this.loading = true;
      let payload = {
        bannerId: this.redactingBanner.bannerId,
        productId: this.productId,
      };
      let response = await api.Banner.addProductToBanner(payload);
      if (response.type === "error") {
        ShowMessage(response.text);
      } else {
        ShowMessage("К баннеру добавлен продукт", true);
        this.$emit("updateBanners");
        this.$emit("update:redactModal", false);
      }
      this.loading = false;
    },
    async addType(typeId) {
      this.loading = true;
      let payload = {
        bannerId: this.redactingBanner.bannerId,
        typeId,
      };
      let response = await api.Banner.addTypeToBanner(payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("К баннеру добавлен тип", true);
        this.$emit("updateBanners");
        this.$emit("update:redactModal", false);
      }
      this.loading = false;
    },
    // Новый метод обновления изображений
    async updateImages() {
      if (!this.updateDesktop && !this.updateMobile && !this.updateMobileWeb) {
        ShowMessage("Выберите хотя бы одно изображение для обновления");
        return;
      }
      this.loading = true;
      const formData = new FormData();
      // Если файл выбран, добавляем его в FormData
      if (this.updateDesktop) {
        formData.append("file", this.updateDesktop, this.redactingBanner.departmentId + this.redactingBanner.bannerName + this.updateDesktop.name);
      }
      if (this.updateMobile) {
        formData.append("file", this.updateMobile, this.redactingBanner.departmentId + this.redactingBanner.bannerName + this.updateMobile.name);
      }
      if (this.updateMobileWeb) {
        formData.append("file", this.updateMobileWeb, this.redactingBanner.departmentId + this.redactingBanner.bannerName + this.updateMobileWeb.name);
      }
      const payload = {
        bannerId: this.redactingBanner.bannerId,
        desktopImageName: this.updateDesktop ? (this.redactingBanner.departmentId + this.redactingBanner.bannerName + this.updateDesktop.name) : "",
        mobileImageName: this.updateMobile ? (this.redactingBanner.departmentId + this.redactingBanner.bannerName + this.updateMobile.name) : "",
        mobileWebImageName: this.updateMobileWeb ? (this.redactingBanner.departmentId + this.redactingBanner.bannerName + this.updateMobileWeb.name) : "",
        file: formData,
      };
      let response = await api.Banner.addImageForBanner(payload);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Изображения обновлены", true);
        this.$emit("updateBanners");
        // Очистим файлы после успешного обновления
        this.updateDesktop = null;
        this.updateMobile = null;
        this.updateMobileWeb = null;
      }
      this.loading = false;
    },
  },
};
</script>
