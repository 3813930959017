<template>
  <v-container style="height: 92vh" fluid>
    <loader v-if="loading"></loader>
    <!-- Открытие изображения -->
    <v-overlay z-index="100" :value="imgModalState" opacity="0.9">
      <v-card>
        <v-img max-height="800" max-width="800" :src="openImgUrl" />
        <v-card-actions>
          <v-btn color="teal" :href="openImgUrl" target="_blank">
            Скачать изображение
          </v-btn>
          <v-spacer />
          <v-btn color="teal" @click="imgModalState = false">
            закрыть окно
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Добавление баннера -->
    <v-overlay z-index="100" :value="overlay">
      <v-card class="addpanel">
        <v-card-title>Добавить Баннер</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="input.name"
            :counter="20"
            label="Название баннера"
          ></v-text-field>

          <!-- Новые поля для привязки характеристик баннера -->
          <v-select
            v-model="input.bannerTypeId"
            :items="bannerTypes"
            label="Тип баннера"
            item-text="name"
            item-value="id"
          />
          <!-- Используем список категорий из Vuex -->
          <v-select
            v-model="input.categoryId"
            :items="CATEGORY"
            label="Категория баннера"
            item-text="name"
            item-value="id"
          />
          <!-- Автозаполнение для выбора магазина (филиала для перехода) -->
          <v-autocomplete
            v-model="input.storeOpeningDepartment"
            :items="DEPARTMENT.cityDepartment"
            item-text="name"
            item-value="id"
            label="Магазин для перехода"
            clearable
          />

          <!-- Файлы изображений -->
          <div>Прикрепите три изображения:</div>
          <v-file-input
            v-model="input.desktop"
            accept="image/*"
            label="Banner Desktop (сайт)"
          />
          <v-file-input
            v-model="input.mobile"
            accept="image/*"
            label="Banner Mobile (мобильный сайт)"
          />
          <v-file-input
            v-model="input.mobileWeb"
            accept="image/*"
            label="Banner Mobile Web (мобильная версия сайта)"
          />

          <div>Размер файла не должен превышать 500кб</div>
          <div>Сервис для сжатия картинок:</div>
          <a target="_blank" href="https://imagecompressor.com/ru/">
            https://imagecompressor.com/ru/
          </a>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="white--text"
            color="teal"
            :disabled="requiredAtleastOneBanner || loadingData"
            @click="isCategoryBanners ? addBannerToCategory() : addBannerToCity()"
          >
            Ок
          </v-btn>
          <v-btn
            :disabled="loadingData"
            class="white--text"
            color="#5d4bd0"
            @click="closeOverlay()"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Основной блок (список баннеров) -->
    <v-card class="mb-5">
      <v-card-title class="header">
        Баннеры
        <v-spacer></v-spacer>
        <v-btn
          v-if="changeBannerPositionBtnState && changeButtonState"
          color="#5d4bd0"
          dark
          class="mr-5"
          @click="clickChangeBtn()"
        >
          Изменить порядок
        </v-btn>
        <v-btn
          v-if="changeButtonState"
          color="#5d4bd0"
          dark
          class="mr-5"
          @click="overlay = !overlay"
        >
          Добавить баннер
        </v-btn>
        <div style="width: 300px">
          <v-select
            v-model="chosenDepartment"
            hide-details
            label="Баннеры для ..."
            dense
            outlined
            :items="cityList"
            item-text="name"
            item-value="id"
          ></v-select>
        </div>
      </v-card-title>
    </v-card>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Инфо</th>
            <th class="text-left">Mobile</th>
            <th class="text-left">Desktop</th>
            <th class="text-left">Web App</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <draggable
          v-model="bannerList"
          tag="tbody"
          :disabled="!changeButtonState"
        >
          <tr
            v-for="bannerItem in bannerList"
            :key="bannerItem.bannerId + bannerItem.categoryId"
          >
            <td>
              <div>
                <p>Имя: {{ bannerItem.bannerName }}</p>
                <p v-if="bannerItem.bannerType">
                  Тип: {{ bannerItem.bannerType.name }}
                </p>
                <p v-if="bannerItem.productId">
                  ID продукта: {{ bannerItem.productId }}
                </p>
                <p v-if="bannerItem.categoryId && !isCategoryBanners">
                  ID Категории: {{ bannerItem.categoryId }}
                </p>
                <p v-if="bannerItem.storeOpeningDepartment">
                  Магазин: {{ bannerItem.storeOpeningDepartment }}
                </p>
                <p v-if="bannerItem.categoryName">
                  Категория: {{ bannerItem.categoryName }}
                </p>
                <p v-if="bannerItem.linkedCategoryId">
                  Категория для перехода: {{ bannerItem.linkedCategoryName }}
                </p>
              </div>
            </td>
            <td>
              <v-img
                max-height="150"
                max-width="250"
                :src="generateUrl(bannerItem.bannerId, 'mobile')"
                @dblclick="openImage(generateUrl(bannerItem.bannerId, 'mobile'))"
              />
            </td>
            <td>
              <v-img
                max-height="150"
                max-width="250"
                :src="generateUrl(bannerItem.bannerId, 'desktop')"
                @dblclick="openImage(generateUrl(bannerItem.bannerId, 'desktop'))"
              />
            </td>
            <td>
              <v-img
                max-height="150"
                max-width="250"
                :src="generateUrl(bannerItem.bannerId, 'mobileWeb')"
                @dblclick="openImage(generateUrl(bannerItem.bannerId, 'mobileWeb'))"
              />
            </td>
            <td>
              <v-btn
                v-if="deleteBannerBtnState"
                icon
                color="error"
                @click="functionDeleteBanner(bannerItem.bannerId)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
              <v-btn color="#5d4bd0" icon @click="addDataToBanner(bannerItem)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-switch
                :input-value="bannerItem.published"
                inset
                hide-details
                color="#5d4bd0"
                :label="bannerItem.published ? 'Скрыть' : 'Показать'"
                @change="changeVisibility(bannerItem.bannerId)"
              ></v-switch>
            </td>
          </tr>
        </draggable>
      </template>
    </v-simple-table>
    <RedactBannerDialog
      :redact-modal.sync="redactModal"
      :redacting-banner="redactingBanner"
      :categoryes="CATEGORY"
      :is-category-banners="isCategoryBanners"
      :chosen-department="chosenDepartment"
      @updateBanners="updateBanners"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import loader from "../components/Loader";
import RedactBannerDialog from "../components/Banners/RedactBannerDialog";
import ShowMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: {
    draggable,
    loader,
    RedactBannerDialog,
  },
  data() {
    return {
      loading: false,
      cityList: [
        { id: 5, name: "Караганда", cityId: 1 },
        { id: 1132, name: "Темиртау", cityId: 2 },
        { id: 35, name: "Астана", cityId: 3 },
        { id: -1, name: "Категорий в Караганде" },
        { id: -2, name: "Категорий в Темиртау" },
        { id: -3, name: "Категорий в Астане" },
      ],
      chosenDepartment: null,
      changeButtonState: false,
      changeBannerPositionBtnState: false,
      deleteBannerBtnState: true,
      overlay: false,
      input: {},
      openImgUrl: null,
      imgModalState: false,
      loadingData: false,
      redactModal: false,
      redactingBanner: null,
      isCategoryBanners: false,
      bannerTypes: [] // Список типов баннеров
    };
  },
  computed: {
    ...mapGetters({
      BANNER: "Banner/STATE",
      CATEGORY: "Category/ALL_CATEGORY",
      DEPARTMENT: "Department/STATE"
    }),
    bannerList: {
      get() {
        return this.BANNER.allBanners;
      },
      set(value) {
        this.changeBannerPositionBtnState = true;
        this.$store.commit("Banner/UPDATE_BANNERS_POSITION", value);
      },
    },
    requiredAtleastOneBanner() {
      if (
        (this.input.desktop || this.input.mobile || this.input.mobileWeb) &&
        this.input.name &&
        this.input.name.length >= 3 &&
        this.input.name.length <= 20
      ) {
        let check = false;
        if (this.input.desktop && this.input.desktop.size > 500000) {
          check = true;
        }
        if (this.input.mobile && this.input.mobile.size > 500000) {
          check = true;
        }
        if (this.input.mobileWeb && this.input.mobileWeb.size > 500000) {
          check = true;
        }
        return check;
      } else {
        return true;
      }
    },
  },
  watch: {
    async chosenDepartment() {
      this.loading = true;
      this.changeBannerPositionBtnState = false;
      this.deleteBannerBtnState = true;
      this.changeButtonState = true;
      if (this.chosenDepartment < 0) {
        this.GET_CATEGORY_BANNERS(this.chosenDepartment * -1);
        this.isCategoryBanners = true;
      } else {
        await this.getBannersByDepartment(this.chosenDepartment);
        this.isCategoryBanners = false;
      }
      this.loading = false;
    },
  },
  mounted() {
    // Загружаем типы баннеров при монтировании
    api.Banner.getBannerTypes().then((data) => {
      this.bannerTypes = data;
      console.log("Banner Types:", data);
    });
    // Загружаем категории, чтобы они были доступны с первого открытия формы создания
    this.getCategory(0);
    const currentCity = this.cityList.find(
      (item) => item.cityId === this.$root.city
    );
    this.chosenDepartment = currentCity.id;
  },
  methods: {
    ...mapActions({
      getBannersByDepartment: "Banner/GET_BANNER_LIST_BY_DEPARTMENT",
      getBannerImage: "Banner/GET_BANNER_IMAGE",
      deleteBanner: "Banner/DELETE_BANNER",
      changePositionsOfBanners: "Banner/UPDATE_BANNER_LIST",
      createBanner: "Banner/CREATE_BANNER",
      addImgBanner: "Banner/ADD_BANNER_IMAGE",
      createCategoryBanner: "Banner/CREATE_CATEGORY_BANNER",
      getCategory: "Category/GET_ALL_CATEGORY",
      GET_CATEGORY_BANNERS: "Banner/GET_CATEGORY_BANNERS",
    }),
    generateUrl(id, mode) {
      let modeParam;
      if (mode === "mobile") {
        modeParam = "mobile";
      } else if (mode === "desktop") {
        modeParam = "desktop";
      } else if (mode === "mobileWeb") {
        modeParam = "mobile_web";
      } else {
        modeParam = "desktop";
      }
      let url = process.env.VUE_APP_API_URL;
      url += `admin/banner/get/image/${id}/${modeParam}`;
      return url;
    },
    clickChangeBtn() {
      this.changeBannerPositionBtnState = false;
      this.changePositionsOfBanners();
    },
    // Создание баннера для города:
    // Передаем: name, published, departmentId, storeOpeningDepartment (если заполнено, иначе chosenDepartment),
    // categoryId = null, bannerTypeId
    async addBannerToCity() {
      this.loadingData = true;
      const payload = {
        name: this.input.name,
        published: this.input.published !== undefined ? this.input.published : true,
        departmentId: this.chosenDepartment,
        storeOpeningDepartment: this.input.storeOpeningDepartment
          ? this.input.storeOpeningDepartment
          : this.chosenDepartment,
        categoryId: null,
        bannerTypeId: this.input.bannerTypeId ? this.input.bannerTypeId : null
      };
      let responseBanner = await this.createBanner(payload);
      this.input.id = responseBanner.data.id;
      let responseImage = await this.addImgBanner(this.input);
      if (responseImage === "success") {
        this.overlay = false;
        await this.getBannersByDepartment(this.chosenDepartment);
        this.input = {};
        ShowMessage("Баннер успешно добавлен", true);
      } else {
        await this.deleteBanner(this.input.id);
        this.overlay = false;
        ShowMessage("Не удалось загрузить баннер на сервер, отключите addblock");
      }
      this.loadingData = false;
    },
    // Создание баннера для категории:
    // Передаем: name, published, departmentId (из отрицательного значения города), 
    // storeOpeningDepartment: null, categoryId, bannerTypeId
    async addBannerToCategory() {
      this.loadingData = true;
      const currentCity = this.cityList.find(
        (item) => item.cityId === this.chosenDepartment * -1
      );
      const payload = {
        name: this.input.name,
        published: true,
        departmentId: currentCity.id,
        storeOpeningDepartment: null,
        categoryId: this.input.categoryId ? this.input.categoryId : null,
        bannerTypeId: this.input.bannerTypeId ? this.input.bannerTypeId : null
      };
      let responseBanner = await this.createCategoryBanner(payload);
      this.input.id = responseBanner.data.id || responseBanner.id;
      let responseImage = await this.addImgBanner(this.input);
      if (responseImage === "success") {
        this.overlay = false;
        await this.GET_CATEGORY_BANNERS(this.chosenDepartment * -1);
        this.input = {};
        ShowMessage("Баннер успешно добавлен", true);
      } else {
        await this.deleteBanner(this.input.id);
        this.overlay = false;
        ShowMessage("Не удалось загрузить баннер на сервер, отключите addblock");
      }
      this.loadingData = false;
    },
    closeOverlay() {
      this.overlay = false;
      this.input = {};
    },
    async functionDeleteBanner(bannerID) {
      await this.deleteBanner(bannerID);
      this.updateBanners();
    },
    openImage(url) {
      this.openImgUrl = url;
      this.imgModalState = true;
    },
    async addDataToBanner(banner) {
      if (!this.CATEGORY.length) {
        this.loading = true;
        await this.getCategory(0);
        this.loading = false;
      }
      this.redactingBanner = banner;
      this.redactModal = true;
    },
    async changeVisibility(bannerId) {
      this.loading = true;
      let response = await api.Banner.changeBannerVisibility(bannerId);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Видимость баннера изменена", true);
        this.updateBanners();
      }
      this.loading = false;
    },
    updateBanners() {
      if (this.isCategoryBanners) {
        this.GET_CATEGORY_BANNERS(this.chosenDepartment * -1);
      } else {
        this.getBannersByDepartment(this.chosenDepartment);
      }
      this.redactingBanner = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .addpanel {
    width: 32rem;
  }
</style>
